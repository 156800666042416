export class AddCertificateLevelDTO {
  title: string = "";
  parent_id: string = "";
  display_order: number = 0;
  level: string = "";
  slug: string = "";
  short_code: string = "";
  offer_program: number = 0;
  qualification_list: number = 0;
  terminal_cat_id: string = "";
  is_active: number = 0;
}
