import { FC, useEffect, useState } from "react";
import { ApplyForTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
interface ApplyForTestProps {
  setOpen: Function;
}

const ApplyForTest: FC<ApplyForTestProps> = ({ setOpen }) => {
  return (
    <ApplyForTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Apply for test</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Test Center</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Test Center</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Test Type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Test Type</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button className="lg-rounded-btn" type="button">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </ApplyForTestMain>
  );
};

export default ApplyForTest;
