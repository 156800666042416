export enum sitePermissions {
  forgetPassword = "forget-password",
  codeVerification = "verification-code",
  createOrganization = "organizations-create",
  organizationListing = "organizations-list",
  editOrganization = "organizations-edit",
  deleteOrganization = "organizations-delete",
  viewOrganization = "organizations-show",
  downloadOrgListPdf = "organizations-download-pdf",
  downloadOrgListExcel = "organizations-download-excel",
  resultTypeListing = "resultType-list",
  resultTypeCreate = "resultType-create",
  viewResultType = "resultType-show",
  editResultType = "resultType-edit",
  deleteResultType = "resultType-delete",
  downloadResultTypePDF = "resultType-download-pdf",
  downloadResultTypeExcel = "resultType-download-excel",
  createAsset = "assets-create",
  assetListing = "assets-list",
  viewAsset = "assets-show",
  editAsset = "assets-edit",
  deleteAsset = "assets-delete",
  downloadAssetListPdf = "assets-download-pdf",
  downloadAssetListExcel = "assets-download-excel",
  createBuilding = "building-create",
  buildingListing = "building-list",
  editBuilding = "building-edit",
  viewBuilding = "building-show",
  deleteBuilding = "building-delete",
  downloadBuildingListPdf = "building-download-pdf",
  downloadBuildingListExcel = "building-delete",
  createStructureType = "structure-types-create",
  structureTypeListing = "structure-types-list",
  editStructureType = "structure-types-edit",
  viewStructureType = "structure-types-show",
  deleteStructureType = "structure-types-delete",
  downloadStructureTypesListPdf = "structure-types-download-pdf",
  downloadStructureTypesListExcel = "structure-types-download-excel",
  createOrgStructure = "structure-create",
  orgStructureListing = "structure-list",
  editOrgStructure = "structure-edit",
  deleteOrgStructure = "structure-delete",
  viewOrgStructure = "structure-show",
  downloadOrgStructureListPdf = "structure-download-pdf",
  downloadOrgStructureListExcel = "structure-download-excel",
  superAdminDashboard = "super-admin-dashboard",
  adminDashboard = "admin-dashboard",
  adminLogin = "admin-login",
  superAdminLogin = "super-admin-login",
  createProgram = "program-create",
  programListing = "program-list",
  editProgram = "program-edit",
  viewProgram = "program-show",
  deleteProgram = "program-delete",
  downloadProgramsPDF = "program-download-pdf",
  downloadProgramsExcel = "program-download-excel",
  manageProgramExamIncharge = "program-manage-exam-incharge",
  manageProgramCriteria = "program-manage-criteria",
  manageProgramSeats = "program-manage-seats",
  programSpecialization = "program-specialization",
  programLinkToEntryTest = "program-link-to-entry-test",
  ePortalLogin = "eportal-login",
  ePortalRegister = "eportal-register",
  ePortalResetPassword = "eportal-reset-password",
  ePortalDashboard = "user-dashboard",
  ePortalEditProfile = "UserProfile-edit",
  ePortalMyVouchers = "eportal-my-vouchers",
  ePortalShortCoursesListing = "ShortCourses-list",
  ePortalShortCoursesApply = "ShortCourses-apply",
  eportalHostelPortal = "hostel-dashboard",
  eportalHostelApplyDashboard = "hostel-apply-now-dashboard",
  eportalHostelApplyNow = "hostel-apply-now",
  eportalHostelVoucherListing = "hostel-vouchers",
  eportalDownloadHostelVoucher = "hostel-download-voucher",
  eportalUploadHostelVoucher = "hostel-upload-voucher",
  eportalUploadCloudHostelVoucher = "hostel-download-voucher-cloud",
  eportalHostelVisitorsInfo = "hostel-visitors-dashboard",
  eportalHostelVisitorCreate = "hostel-visitors-create",
  eportalHostelVisitorEdit = "hostel-visitors-edit",
  eportalHostelVisitorDelete = "hostel-visitors-delete",
  eportalHostelMedicalInfo = "medical-and-others-dashboard",
  eportalHostelMedicalCreate = "medical-and-others-create",
  eportalHostelEnrollmentInfo = "enrollment-dashboard",
  createHostel = "hostel-create",
  hostelListing = "hostel-list",
  hostelDelete = "hostel-delete",
  hostelEdit = "hostel-edit",
  downloadHostelPDF = "download-hostel-pdf",
  downloadHostelExcel = "download-hostel-excel",
  createHostelRoom = "hostel-room-create",
  hostelRoomsListing = "hostel-room-list",
  hostelRoomsDelete = "hostel-room-delete",
  hostelRoomsEdit = "hostel-room-edit",
  hostelRoomsDownloadPDF = "hostel-room-download-pdf",
  hostelRoomsDownloadExcel = "hostel-room-download-excel",
  applicantListing = "hostel-applicant-list",
  createApplicant = "hostel-applicant-create",
  editHostelApplicant = "hostel-applicant-edit",
  deleteHostelApplicant = "hostel-applicant-delete",
  downloadHostelApplicantPDF = "hostel-applicant-download-pdf",
  downloadHostelApplicantExcel = "hostel-applicant-download-excel",
  createHostelMerit = "hostel-merit-list-create",
  hostelMeritListing = "hostel-merit-list-listing",
  editHostelMerit = "hostel-merit-list-edit",
  deleteHostelMerit = "hostel-merit-list-delete",
  downloadHostelMeritPDF = "hostel-merit-list-download-pdf",
  downloadHostelMeritExcel = "hostel-merit-list-download-excel",
  createHostelSession = "hostel-session-create",
  hostelSessionListing = "hostel-session-listing",
  editHostelSession = "hostel-session-edit",
  deleteHostelSession = "hostel-session-delete",
  downloadHostelSessionPDF = "hostel-session-download-pdf",
  downloadHostelSessionExcel = "hostel-session-download-excel",
  hostelFinanceManagement = "hostel-finance-management",
  viewAppliedApplicant = "applicants-applied-for-hostel-detail",
  rejectAppliedApplicant = "applicants-applied-for-hostel-reject",
  acceptAppliedApplicant = "applicants-applied-for-hostel-accept",
  filterAppliedApplicants = "applicants-applied-for-hostel-filter",
  appliedApplicant = "applicants-applied-for-hostel-list",
  downloadAppliedApplicantsPDF = "applicants-applied-for-hostel-download-pdf",
  downloadAppliedApplicantsExcel = "applicants-applied-for-hostel-download-excel",
  applicantEnrollment = "applicants-hostel-enrollment-list",
  editApplicantEnrollment = "applicants-hostel-enrollment-edit",
  deleteApplicantEnrollment = "applicants-hostel-enrollment-delete",
  downloadApplicantEnrollmentPDF = "applicants-hostel-enrollment-download-pdf",
  downloadApplicantEnrollmentExcel = "applicants-hostel-enrollment-download-excel",
  applicantEnrollmentFilter = "applicants-hostel-enrollment-filter",
  registeredApplicantList = "hostel-registered-applicant-list",
  createRegisteredApplicant = "hostel-registered-applicant-create",
  editRegisteredApplicant = "hostel-registered-applicant-edit",
  deleteRegisterApplicant = "hostel-registered-applicant-delete",
  registeredApplicantFilter = "hostel-registered-applicant-filter",
  downloadRegisteredApplicantPDF = "hostel-registered-applicant-download-pdf",
  downloadRegisteredApplicantExcel = "hostel-registered-applicant-download-excel",
  hostelApplicantMeritList = "hostel-applicant-merit-list-listing",
  editHostelApplicantMeritList = "hostel-applicant-merit-list-edit",
  deleteHostelApplicantMeritList = "hostel-applicant-merit-list-delete",
  hostelApplicantMeritListFilter = "hostel-applicant-merit-list-filter",
  downloadHostelApplicantMeritListPDF = "hostel-applicant-merit-list-download-pdf",
  downloadHostelApplicantMeritListExcel = "hostel-applicant-merit-list-download-excel",
  academicSessionListing = "Academics-session-listing",
  createAdmissionSession = "sessions-create",
  viewEportalProfile = "UserProfile-show",
  admissionSessionListing = "sessions-list",
  admissionCampaignListing = "AdmissionCampaigns-list",
  createAdmissionCampaign = "AdmissionCampaigns-create",
  ViewAdmissionCampaign = "AdmissionCampaigns-show",
  EditAdmissionCampaign = "AdmissionCampaigns-edit",
  deleteAdmissionCampaign = "AdmissionCampaigns-delete",
  admissionCampaignLinkProgram = "AdmissionCampaigns-link-program",
  downloadAdmissionCampaignPDF = "AdmissionCampaigns-download-pdf",
  downloadAdmissionCampaignExcel = "AdmissionCampaigns-download-excel",
  admissionStudentListing = "StudentApplications-list",
  admissionStudentDownloadListing = "StudentApplications-download-list",
  admissionStudentDownloadPDF = "StudentApplications-download-pdf",
  admissionStudentDownloadExcel = "StudentApplications-download-excel",
  admissionApplicantsListing = "StudentApplicationsView-list",
  downloadAdmissionApplicantsPDF = "StudentApplicationsView-download-pdf",
  downloadAdmissionApplicantsExcel = "StudentApplicationsView-download-excel",
  editAdmissionApplicants = "StudentApplicationsView-edit",
  deleteAdmissionApplicants = "StudentApplicationsView-delete",
  emailAdmissionApplicants = "StudentApplicationsView-email",
  phoneAdmissionApplicants = "StudentApplicationsView-phone",
  editAdmissionDocument = "ManageDocuments-edit",
  createAdmissionDocument = "ManageDocuments-create",
  admissionDocumentListing = "ManageDocuments-list",
  admissionDocumentDelete = "ManageDocuments-delete",
  downloadAdmissionDocumentsPDF = "ManageDocuments-download-pdf",
  downloadAdmissionDocumentsExcel = "ManageDocuments-download-excel",
  admissionTestTypesListing = "TestTypes-list",
  createAdmissionTestType = "TestTypes-create",
  editAdmissionTestType = "TestTypes-edit",
  deleteAdmissionTestType = "TestTypes-delete",
  showAdmissionTestType = "TestTypes-show",
  downloadAdmissionTestTypePDF = "TestTypes-download-pdf",
  downloadAdmissionTestTypeExcel = "TestTypes-download-excel",
  studentRegListing = "RegisteredStudents-list",
  studentRegEdit = "RegisteredStudents-edit",
  studentRegDelete = "RegisteredStudents-delete",
  studentRegEmail = "RegisteredStudents-email",
  studentRegPhone = "RegisteredStudents-phone",
  downloadStudentRegPDF = "RegisteredStudents-download-pdf",
  downloadStudentRegExcel = "RegisteredStudents-download-excel",
  admissionMeritListing = "AdmissionMeritList-list",
  admissionMeritListEdit = "AdmissionMeritList-edit",
  downloadAdmissionMeritListPDF = "AdmissionMeritList-download-pdf",
  downloadAdmissionMeritListExcel = "AdmissionMeritList-download-excel",
  admissionMeritListForward = "AdmissionMeritList-forward",
  admissionMeritListDelete = "AdmissionMeritList-delete",
  admissionMeritListDownload = "AdmissionMeritList-download",
  admissionMeritListAllField = "AdmissionMeritList-download-allFields",
  admissionOfferLetterListing = "OfferLetterTemplates-list",
  createAdmissionOfferLetter = "OfferLetterTemplates-create",
  editAdmissionOfferLetter = "OfferLetterTemplates-edit",
  showAdmissionOfferLetter = "OfferLetterTemplates-show",
  deleteAdmissionOfferLetter = "OfferLetterTemplates-delete",
  downloadAdmissionOfferLetterPDF = "OfferLetterTemplates-download-pdf",
  downloadAdmissionOfferLetterExcel = "OfferLetterTemplates-download-excel",
  editAdmissionBoard = "edit-admission-board",
  editAdmissionCertificate = "edit-admission-certificate",
  admissionManageDocumentsMasterListing = "admission-manage-documents-master-listing",
  createAdmissioneligibilityTemplateHeaders = "EligibilityTemplateHeader-create",
  admissioneligibilityTemplateHeadersListing = "EligibilityTemplateHeader-list",
  admissioneligibilityTemplateHeadersDelete = "EligibilityTemplateHeader-delete",
  admissioneligibilityTemplateHeadersEdit = "EligibilityTemplateHeader-edit",
  admissioneligibilityTemplateHeadersShow = "EligibilityTemplateHeader-show",
  downloadAdmissionEligibilityTemplateHeaderPDF = "EligibilityTemplateHeader-download-pdf",
  downloadAdmissionEligibilityTemplateHeaderExcel = "EligibilityTemplateHeader-download-excel",
  createAdmissioneligibilityTemplateBody = "EligibilityTemplateBody-create",
  admissioneligibilityTemplateBodiesListing = "EligibilityTemplateBody-list",
  admissioneligibilityTemplateBodyDelete = "EligibilityTemplateBody-delete",
  admissioneligibilityTemplateBodyEdit = "EligibilityTemplateBody-edit",
  admissioneligibilityTemplateBodyShow = "EligibilityTemplateBody-show",
  downloadAdmissionEligibilityTemplateBodyPDF = "EligibilityTemplateBody-download-pdf",
  downloadAdmissionEligibilityTemplateBodyExcel = "EligibilityTemplateBody-download-excel",
  admissionQuotasListing = "AdmissionQuota-list",
  createAdmissionQuotas = "AdmissionQuota-create",
  editAdmissionQuotas = "AdmissionQuota-edit",
  showAdmissionQuotas = "AdmissionQuota-show",
  deleteAdmissionQuotas = "AdmissionQuota-delete",
  downloadAdmissionQuotaPDF = "AdmissionQuota-download-pdf",
  downloadAdmissionQuotaExcel = "AdmissionQuota-download-excel",
  ePortalChallanListing = "UserChallan-generate",
  eportalChallanDownloadPDF = "UserChallan-download-pdf",
  eportalChallanDownloadExcel = "UserChallan-download-excel",
  certificateLinkListing = "LinkedCertificates-list",
  certificateLinkCreate = "LinkedCertificates-create",
  viewCertificateLink = "LinkedCertificates-show",
  editLinkedCertificate = "LinkedCertificates-edit",
  deleteLinkedCertificate = "LinkedCertificates-delete",
  downloadLinkedCertificatePDF = "LinkedCertificates-download-pdf",
  downloadLinkedCertificateExcel = "LinkedCertificates-download-excel",
  roleManagementListing = "admin-roles-listing",
  rollManagementCreate = "admin-roles-create",
  roleManagementShow = "admin-roles-show",
  roleManagementEdit = "admin-roles-edit",
  roleManagementDelete = "admin-roles-delete",
  roleManagementPDFDownload = "admin-roles-download-pdf",
  roleManagementExcelDownload = "admin-roles-download-excel",
  boardManagementListing = "Boards-list",
  boardManagementCreate = "Boards-create",
  boardManagementDelete = "Boards-delete",
  boardManagementEdit = "Boards-edit",
  boardManagementShow = "Boards-show",
  downloadBoardManagementPDF = "Boards-download-pdf",
  downloadBoardManagementExcel = "Boards-download-excel",
  subjectManagementListing = "Subjects-list",
  subjectManagementCreate = "Subjects-create",
  subjectManagementShow = "Subjects-show",
  subjectManagementEdit = "Subjects-edit",
  subjectManagementDelete = "Subjects-delete",
  downloadSubjectManagementPDF = "Subjects-download-pdf",
  downloadSubjectManagementExcel = "Subjects-download-excel",
  certificateManagementListing = "certificate-management-listing",
  certificateManagementCreate = "certificate-management-create",
  superAdminManagementListing = "super-admins-list",
  superAdminManagementCreate = "super-admins-create",
  superAdminManagementDelete = "super-admins-delete",
  superAdminManagementEdit = "super-admins-edit",
  superAdminManagementShow = "super-admins-show",
  superAdminManagementPDFDownload = "super-admins-download-pdf",
  superAdminManagementExcelDownload = "super-admins-download-excel",
  superRoleManagementListing = "roles-list",
  superRoleManagementCreate = "roles-create",
  superRoleManagementShow = "roles-show",
  superRoleManagementEdit = "roles-edit",
  superRoleManagementDelete = "roles-delete",
  superRoleManagementPDFDownload = "roles-download-pdf",
  superRoleManagementExcelDownload = "roles-download-excel",
  permissionManagementListing = "permissions-list",
  permissionManagementCreate = "permissions-create",
  permissionManagementEdit = "permissions-edit",
  permissionManagementDelete = "permissions-delete",
  permissionManagementPDFDownload = "permissions-download-pdf",
  permissionManagementExcelDownload = "permissions-download-excel",
  moduleManagement = "modules-list",
  moduleManagementCreate = "modules-create",
  moduleManagementDelete = "modules-delete",
  moduleManagementEdit = "modules-edit",
  linkedSpecializationListing = "LinkedSpecialization-list",
  createLinkedSpecialization = "LinkedSpecialization-create",
  viewLinkedSpecialization = "LinkedSpecialization-show",
  EditLinkedSpecialization = "LinkedSpecialization-edit",
  deleteLinkedSpecialization = "LinkedSpecialization-delete",
  downloadLinkedSpecializationPDF = "LinkedSpecialization-download-pdf",
  downloadLinkedSpecializationExcel = "LinkedSpecialization-download-excel",
  linkedProgramEntryTestListing = "LinkProgramToEntryTest-list",
  createLinkedProgramEntryTest = "LinkProgramToEntryTest-create",
  deleteLinkedProgramEntryTest = "LinkProgramToEntryTest-delete",
  editLinkedProgramEntryTest = "LinkProgramToEntryTest-edit",
  showLinkedProgramEntryTest = "LinkProgramToEntryTest-show",
  downloadLinkedProgramEntryTestPDF = "LinkProgramToEntryTest-download-pdf",
  downloadLinkedProgramEntryTestExcel = "LinkProgramToEntryTest-download-excel",
  academicSessionOrganizationListing = "academic-session-organization-listing",
  createAcademicSessionOrganization = "create-academic-session-organization",
  admissionProgramListing = "admission-program-listing",
  admissionSubjectManagementListing = "Subjects-list",
  createAdmissionSubjectManagement = "Subjects-create",
  admissionBoardManagementListing = "admission-board-management-listing",
  createAdmissionBoardManagement = "create-admission-board-management",
  admissionCertificateManagementListing = "DegreeCertificate-list",
  createAdmissionCertificateManagement = "DegreeCertificate-create",
  editAdmissionCertificateManagement = "DegreeCertificate-edit",
  showAdmissionCertificateManagement = "DegreeCertificate-show",
  deleteAdmissionCertificateManagement = "DegreeCertificate-delete",
  downloadAdmissionCertificateManagementPDF = "DegreeCertificate-download-pdf",
  downloadAdmissionCertificateManagementExcel = "DegreeCertificate-download-excel",
  linkedProgramAdmissionListing = "linked-program-admission-listing",
  meritKeysListing = "MeritKeys-list",
  createMeitKeys = "MeritKeys-create",
  editMeritKeys = "MeritKeys-edit",
  showMeritKeys = "MeritKeys-show",
  deleteMeritKeys = "MeritKeys-delete",
  downloadMeritKeysPDF = "MeritKeys-download-pdf",
  downloadMeritKeysExcel = "MeritKeys-download-excel",
  meritListFormulaListing = "MeritListFormula-list",
  createMeritListFormula = "MeritListFormula-create",
  editMeritListFormula = "MeritListFormula-edit",
  showMeritListFormula = "MeritListFormula-show",
  deleteMeritListFormula = "MeritListFormula-delete",
  downloadMeritListFormulaPDF = "MeritListFormula-download-pdf",
  downloadMeritListFormulaExcel = "MeritListFormula-download-excel",
  majorCategoriesListing = "AssetsMajorCategory-list",
  CreateMajorCategories = "AssetsMajorCategory-create",
  viewAssetsMajorCategories = "AssetsMajorCategory-show",
  editAssetsMajorCategories = "AssetsMajorCategory-edit",
  deleteAssetsMajorCategories = "AssetsMajorCategory-delete",
  downloadAssetsMajorCategoriesPDF = "AssetsMajorCategory-download-pdf",
  downloadAssetsMajorCategoriesExcel = "AssetsMajorCategory-download-excel",
  minorCategoriesListing = "AssetsMinorCategory-list",
  createMinorCategories = "AssetsMinorCategory-create",
  viewMinorCategories = "AssetsMinorCategory-show",
  editMinorCategories = "AssetsMinorCategory-edit",
  deleteMinorCategories = "AssetsMinorCategory-delete",
  downloadMinorCategoriesPDF = "AssetsMinorCategory-download-pdf",
  downloadMinorCategoriesExcel = "AssetsMinorCategory-download-excel",
  roomsListing = "rooms-listing",
  createRooms = "create-rooms",
  eportalMyVouchersListing = "eportal-my-vouchers-listing",
  eportalCareersListing = "eportal-careers-listing",
  eportalAdmissionsListing = "Applications-list",
  eportalTestingServiceListing = "TestingServices-MyTestResult-list",
  buildingTypeListing = "BuildingType-list",
  createBuildingType = "BuildingType-create",
  viewBuildingTop = "BuildingType-show",
  editBuildingType = "BuildingType-edit",
  deleteBuildingType = "BuildingType-delete",
  downloadBuildingTypePDF = "BuildingType-download-pdf",
  downloadBuildingTypeExcel = "BuildingType-download-excel",
  createEportalAdmissionList = "Applications-apply-in-othe-program",
  quotasAdmissionListing = "AdmissionQuota-list",
  createQuotasAdmissionList = "AdmissionQuota-create",
  manageMeritListListing = "ManageMeritList-list",
  createAdmissionManageMeritList = "ManageMeritList-create",
  qoutasListProgramListing = "quota-list-program-listing",
  eligibilityTemplatesListing = "ProgramLinkTemplate-list",
  eligibilityTemplatesCreate = "ProgramLinkTemplate-create",
  programLinkMeritList = "ProgramLinkMerit-list",
  createProgramLinkMeritList = "ProgramLinkMerit-create",
  systemLogsListing = "Logs-system-logs-listing",
  financeDashboard = "Finance-finance-dashboard",
  systemUsersListing = "Users-system-users-listing",
  createSystemUsers = "Users-create-system-users",
  systemMenusListing = "Menus-system-menus-listing",
  createSystemMenus = "Menus-create-system-menus",
  unregisteredMenusListing = "Menus-system-unregistered-menus-listing",
  userAuditListing = "Audit-system-user-audit-listing",
  workflowListing = "Workflow-system-workflow-listing",
  createWorkflow = "Workflow-create-system-workflow",
  createEportalCareer = "career-create-eportal-careers",
  careerEportalListing = "career-eportal-careers-listing",
  studentFinancialClearanceListing = "Finance-student-financial-clearance-listing",
  checkFinancialChallanStatus = "Finance-check-financial-challan-status",
  fineSlotsListing = "Finance-fine-slots-listing",
  createFineSlot = "Finance-create-fine-slot",
  templateProgramAccountCodeListing = "Finance-template-program-account-code-listing",
  voucherTypesListing = "Finance-voucher-types-listing",
  createVoucherType = "Finance-create-voucher-type",
  createFinanceApplications = "Finance-create-finance-application",
  financeApplicationsListing = "Finance-finance-applications-listing",
  applicationTemplateLinkListing = "Finance-application-template-link-listing",
  createApplicationTemplateLink = "Finance-create-application-template-link",
  createVoucherTemplateHeader = "Finance-create-voucher-template-header",
  voucherTemplateHeaderListing = "Finance-voucher-template-header-listing",
  createBank = "Finance-create-bank",
  banksListing = "Finance-banks-listing",
  bankTransactionLogsListing = "Finance-bank-transactions-logs-listing",
  bankScrollLogsListing = "Finance-bank-scroll-logs-listing",
  createVoucherParticular = "Finance-create-voucher-particular",
  voucherParticularListing = "Finance-voucher-particulars-listing",
  masterBookListing = "Finance-master-book-listing",
  masterBookTransactionDetail = "Finance-master-book-transaction-detail",
  showMasterBookTransactionDetail = "Finance-show-master-book-transaction-detail",
  eportalAppliedJobListing = "career-applied-jobs-listing",
  eportalCarrerProfile = "career-view-profile",
  careerDesignationListing = "career-designation-listing",
  createCareerDesignation = "career-create-career-designation",
  careerAdvertisementListing = "career-advertisement-listing",
  createCareerAdvertisement = "career-create-career-advertisement",
  careerPostTemplateListing = "career-post-template-listing",
  createCareerPostTemplate = "career-create-career-post-template",
  careerPostListing = "career-post-listing",
  createCareerPost = "career-create-career-post",
  careerApplicantListing = "career-applicant-listing",
  voucherTemplateBodiesListing = "Finance-template-body-list",
  financeInstallmentListing = "Finance-installments-list",
  createFinanceInstallment = "Finance-installments-create",
  installmentSlotListing = "Finance-installment-slot-list",
  createInstallmentSlot = "Finance-installment-slot-create",
  slotsInstallmentParticulars = "Finance-installment-slot-particulars-list",
  CertificateLevelList = "CertificateLevels-list",
  createCertificateLevels = "CertificateLevels-create",
  authoritiesListing = "Authorities-list",
  createAuthorities = "Authorities-create",
  viewMembers = "Authorities-view-member",
  viewDocuments = "Authorities-view-documents",
  senateMeetingList = "Authorities-SenataMeetings-list",
  createSenateMeeting = "Authorities-SenataMeetings-craete",
  councilNotificationsListing = "Authorities-CouncilNotification-list",
  createCouncilNotifications = "Authorities-CouncilNotification-create",
  authorityTypeListing = "Authorities-CommitteeType-list",
  createAuthorityType = "Authorities-CommitteeType-create",
  authorityMemberTypeListing = "Authorities-MemberType-list",
  createAuthorityMemberType = "Authorities-MemberType-create",
  testingServicesDashboard = "TestingServices-dashboard",
  TestingServicesTestCenterlist = "TestingServices-TestCenter-list",
  TestingServicesTestCentercreate = "TestingServices-TestCenter-create",
  TestingServicesTestSchedulelist = "TestingServices-TestSchedule-list",
  createTestingServicesTestSchedule = "TestingServices-TestSchedule-create",
  testingServicespublishresult = "TestingServices-publish-result",
  testingServicescandidateTestResultlist = "TestingServices-candidate-TestResult-list",
  testingCenterRooms = "TestingServices-TestCenterRooms-list",
  createTestingServicesTestCenterRooms = "TestingServices-TestCenterRooms-create",
  testingServicesTestScheduleSeatingPlanlist = "TestingServices-TestSchedule-SeatingPlan-list",
  testingServicesTestScheduleSeatingPlanRoomslist = "TestingServices-TestSchedule-SeatingPlan-Rooms-list",
  viewTestApplicants = "TestingServices-TestSchedule-SeatingPlan-application-list",
  testingServicescandidatelisting = "TestingServices-candidate-listing",
  reportCategoriesList = "QEC-ReportCategories-list",
  createreportCategories = "QEC-ReportCategories-create",
  qecReportManagelist = "QEC-Report-list",
  createQecReportManage = "QEC-Report-create",
  qecSurveyReportlist = "QEC-SurveyReport-list",
  createQecSurveyReport = "QEC-SurveyReport-create",
  surveyListing = "QEC-Survey-list",
  createSurvey = "QEC-Survey-create",
  surveyTypeListing = "QEC-SurveyType-list",
  createSurveyType = "QEC-SurveyType-create",
  questionListing = "QEC-SurveyQuestion-list",
  createQuestion = "QEC-SurveyQuestion-create",
  libraryPublisherlist = "Library-Publisher-list",
  createLibraryPublisher = "Library-Publisher-create",
  librarySellerlist = "Library-Seller-list",
  createLibrarySeller = "Library-Seller-create",
  libraryBillslist = "Library-Bills-list",
  createLibraryBills = "Library-Bills-create",
  libraryBookslist = "Library-Books-list",
  createLibraryBooks = "Library-Books-create",

  libraryLanguagelist = "Library-Language-list",
  createLibraryLanguage = "Library-Language-create",
  libraryAccessRegisterlist = "Library-AccessRegister-list",

  legalFrameworkList = "Legal-FrameworkDocument-list",
  createLegalFramework = "Legal-FrameworkDocument-create",
  legalOrganizationList = "Legal-DocumentOrganization-list",
  createLegalOrganization = "Legal-DocumentOrganization-create",
  frameworkDocumentTypeList = "Legal-FrameworkDocumentType-list",
  createFrameworkDocumentType = "Legal-FrameworkDocumentType-create",
  billRequestList = "Legal-BillRequest-list",
  createBillRequest = "Legal-BillRequest-create",
  legalCasesList = "Legal-LegalCase-list",
  createLegalCases = "Legal-LegalCase-create",
  legalAdvisorListing = "Legal-LegalAdvisor-list",
  createLegalAdvertisor = "Legal-LegalAdvisor-create",
  legalCourtList = "Legal-Courts-list",
  createLegalCourt = "Legal-Courts-create",
  legalStatusList = "Legal-Status-list",
  createLegalStatus = "Legal-Status-create",
  legalMatterTypeListing = "Legal-MatterTypes-list",
  createLegalMatterType = "Legal-MatterTypes-create",
  advisorRatesListing = "Legal-AdvisorRates-list",
  createAdvisorRates = "Legal-AdvisorRates-create",

  oricDashboard = "ORIC-Dashboard",
  oricEventTypesListing = "Oric-Event-Types-List",
  createOricEventTypes = "Oric-Event-Types-Create",
  oricConferenceListing = "Oric-Conferences-Listing",
  createOricConference = "Oric-Conferences-Create",
  oricCallListing = "Oric-Calls-Listing",
  createOricCalls = "Oric-Call-Create",
  oricCallCategoriesListing = "Oric-Call-Category-Listing",
  createOricCallCategories = "Oric-Call-Category-Create",
  oricCareerDevelopmentListing = "Oric-Career-Development-Listing",
  keyPerformanceIndicatorYearListing = "Key-Performance-Indicator-Year-Listing",
  createKeyPerformanceIndicatorYear = "Key-Performance-Indicator-Year-Create",
  oricKPIScore = "ORIC-KPI-Score",
  researchIncentivesListing = "Research-Incentives-Listing",
  createResearchIncentives = "Research-Incentives-Create",
  oricCommunityListing = "ORIC-community-list",
  createOricCommunity = "ORIC-community-create",
  oricMouPartyListing = "ORIC-MouParty-list",
  createOricMouParty = "ORIC-MouParty-create",
  oricMouListing = "ORIC-Mou-list",
  createOricMou = "ORIC-Mou-create",
  oricProjectDateListing = "ORIC-projectDate-list",
  createOricProjectDate = "ORIC-projectDate-create",
  oricProjectlisting = "ORIC-project-list",
  createOricProject = "ORIC-project-create",
  oricResearchGrantApplicationslisting = "ORIC-researchGrantApplications-list",
  oricResearchProjectListing = "ORIC-researchProject-list",
  createOricResearchProject = "ORIC-researchProject-create",
  oricMajorSubjectsListing = "ORIC-majorSubjects-list",
  createOricMajorSubjects = "ORIC-majorSubjects-create",
  // academic permissions
  createAcademicCourse = "Academics-course-create",
  academicCourseListing = "Academics-course-listing",

  academicSessionManagementListing = "Academics-session-management-listing",
  academicEnrollmentsListing = "Academics-enrollments-listing",
  createAcademicCourseGroup = "Academics-courseGroup-create",
  academicCourseGroupListing = "Academics-courseGroup-listing",

  createCommonCoursesPools = "Academics-commonCoursesPool-create",
  commonCoursesPoolsListing = "Academics-commonCoursesPool-listing",
  allocateCourseRoomListing = "Academics-allocateCourseRoom-listing",
  academicGradeBookListing = "Academics-gradeBook-listing",
  consolidatedResultsListing = "consolidated-results-listing",
  sectionCoursesListing = "section-courses-listing",
  submittedCoursesListing = "submitted-courses-listing",
  financeAcademicSessionListing = "finance-academic-sessions-listing",
  createFinanceAcademicSession = "create-finance-academic-session",
  financeSessionStudentsListing = "finance-session-students-listing",
  approvedReceiptAcknowledgedListing = "approved-receipt-acknowledged-listing",
  appRecAckViewAwardList = "approved-receipt-view-award-list",
  poolTeachersListing = "Academics-poolTeacher-list",
  enrollPoolTeacher = "enroll-pool-teacher",
  academicStudent = "academic-student",
  academicStudentFine = "academic-student-fine",
  academicDefaulterStudent = "academic-defaulter-student",
  academicFeeTypeListing = "academic-fee-type-listing",
  createAcademicFeeType = "create-academic-fee-type",
  createAcademicFeePlan = "create-academic-fee-plan",
  academicFeePlansListing = "academic-fee-plan-listing",
  academicStudentClearence = "academic-student-clearence",
  academicManageExamIncharge = "Academics-ExamIncharge-show",
  academicExamIncharge = "Academics-ExamIncharge-list",
  academicSectionRoomsListing = "Academics-SesctionRooms-list",
  manageGradeTemplates = "Academics-gradeTemplateLinkGrades-show",
  academicSyncGradeTemplates = "Academics-gradeTemplateSync-list",
  studentClearenceListing = "Academic-StudentClearence-Listing",
  createClearanceAuthority = "create-clearance-authority",
  clearanceAuthoritiesListing = "clearance-authorities-listing",
  createStudentStatus = "create-student-status",
  studentStatusListing = "student-status-listing",
  createStudentGroup = "create-student-group",
  studentGroupListing = "student-groups-listing",
  createStudentSpecialization = "create-student-specialization",
  studentSpecializationsListing = "student-specializations-listing",
  createAcademicSemesterType = "create-academic-semester-type",
  academicSemesterTypesListing = "academic-semester-types-listing",
  createAcademicLetterGrade = "Academics-letterGrades-create",
  academicLetterGradesListing = "Academics-letterGrades-list",
  createAcademicSection = "create-academic-section",
  academicSectionsListing = "academic-sections-listing",
  createAcademicTemplateCourse = "Academics-templateCourses-create",
  academicTemplateCoursesListing = "Academics-templateCourses-list",
  createAcademicInternship = "create-academic-internship",
  academicInternshipsListing = "academic-internships-listing",
  hrManagementLeaveDashboard = "leaves-dashboard",
  createOvertimeSlot = "create-overtime-slot",
  overtimeSlotsListing = "overtime-slots-listing",
  absentEmployeesListing = "absent-employees-listing",
  leaveCancellationListing = "leave-cancellation-listing",
  releasedLeaveBalancingReportListing = "released-leave-balancing-report-listing",
  academicManageFeePlanListing = "manage-academic-fee-plan",
  academicFeeTransactionsListing = "academic-fee-transactions-listing",
  createAcademicFeeReceipt = "create-academic-fee-receipt",
  academicPaidVouchersListing = "academic-paid-vouchers-listing",
  academicExamTypeListing = "academic-exam-types-listing",
  createAcademicExamType = "create-academic-exam-type",
  createAcademicExam = "create-academic-exam",
  academicExamsListing = "academic-exams-listing",
  createAcademicInvigilator = "create-academic-invigilators",
  academicInvigilatorsListing = "academic-invigilators-listing",
  createAcademicAuthority = "create-academic-authority",
  academicAuthorityListing = "academic-authority-listing",
  createAcademicAuthorityUser = "create-academic-authority-user",
  academicAuthorityUsersListing = "academic-authority-users-listing",
  createAcademicGradeTemplate = "Academics-gradeTemplate-create",
  academicGradeTemplatesListing = "Academics-gradeTemplate-list",
  createAcademicStatus = "Academics-AcademicStatus-create",
  academicStatusListing = "Academics-AcademicStatus-list",

  remunerationListing = "HR-Remuneration-list",
  overtimeManualEntryListing = "HR-OverTime-ManualEntry-list",
  remunerationSettingsListing = "HR-Remuneration-settings",
  employeesOvertimeListing = "HR-EmployeesOvertime-list",
  requestedLeavesListing = "HR-RequestedLeaves-list",
  myAttendanceList = "HR-Attendance-list",
  createLeaveRequestion = "HR-LeaveRequistion-create",
  leaveTypeListing = "HR-LeaveTypes-list",
  createLeaveTypes = "HR-LeaveTypes-create",
  setOffDays = "HR-Set-OffDays",
  requestDetails = "HR-LeaveLedger-Request-details",
  academicPlanofStudies = "Academics-PlanOfStudies-List",
  createStudyPlans = "Academics-PlanOfStudies-create",
  createAcademicSession = "Academics-AcademicSession-create",
  planOfStudyCourseListing = "Academics-PlanOfStudiesCourses-List",
  planOfStudyStudentListing = "Academics-PlanOfStudyStudents-List",
  viewAcademicStatus = "Academics-AcademicStatus-show",
  deleteAcademicStatus = "Academics-AcademicStatus-delete",
  editAcademicStatus = "Academics-AcademicStatus-edit",
  downloadAcademicStatusPDF = "Academics-AcademicStatus-download-pdf",
  downloadAcademicStatusExcel = "Academics-AcademicStatus-download-excel",
  deleteUserByAdmin = "Users-system-users-delete",
  EditAuthorityMemberType = "Authorities-MemberType-edit",
  DeleteAuthorityMemberType = "Authorities-MemberType-delete",
  EditAuthorityCommitteeType = "Authorities-CommitteeType-edit",
  DeleteAuthorityCommitteeType = "Authorities-CommitteeType-delete",
  hrManagementLeavesLedgerListing = "hrm-leaves-ledger-listing",
  UpdateAuthorityNotification = "Authorities-CouncilNotification-edit",
  DeleteAuthorityNotification = "Authorities-CouncilNotification-delete",
  editAuthority = "Authorities-edit",
  deleteAuthority = "Authorities-delete",
  deleteSenateMeeting = "Authorities-SenataMeetings-delete",
  editSenateMeeting = "Authorities-SenataMeetings-edit",
  createAuthorityBoard = "Authorities-boards-create",
  authorityBoardsListing = "Authorities-boards-list",
  createTeachersTitle = "create-teachers-title",
  teachersTitlesListing = "teachers-titles-listing",
  createCourseType = "create-course-type",
  courseTypesListing = "course-types-listing",
  editAuthorityBoard = "Authorities-boards-edit",
  deleteAuthorityBoard = "Authorities-boards-delete",
  setHolidaysListing = "HR-Set-Holidays",
}
