import { FC, Fragment, useState } from "react";
import {
  TestingServiceListingSection,
  TestingServiceListingTop,
  TestingServiceListingMain,
  TestWrapper,
  TestCard,
  TestListingSection,
} from "./style";
import {
  DownloadApplicationSvg,
  DownloadChallanSvg,
  DownloadDocumentSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TabPrimaryActionMenu,
  UploadChallanSvg,
} from "assets/images/common/svgs";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import DataNotFound from "components/particles/table/data-not-found";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { SmallWhiteRightArrow } from "assets/images/e-portal/svgs";
import ApplyForTest from "./components/apply-for-test";

const EportalTestingServices: FC = ({}) => {
  const navigate = useNavigate();

  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const columns: string[] = [
    "App#",
    "Test",
    "Test Center",
    "Apply Date  ",
    "Last Date",
    "Total Marks",
    "Obtained Marks",
    "Result Status",
    "Fee Status",
    "Action",
  ];

  // Define a type for your state
  type RowData = {
    isDropdownOpen: boolean;
  };

  const [data, setData] = useState<RowData[]>([
    { isDropdownOpen: false },
    { isDropdownOpen: false },
    { isDropdownOpen: false },
  ]);

  const toggleRowExpand = (index: number) => {
    const updatedData = [...data]; // Copy the data array
    if (updatedData[index]) {
      updatedData[index].isDropdownOpen = !updatedData[index].isDropdownOpen; // Toggle the dropdown state
      setData(updatedData); // Update the state with the modified data
    }
  };
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Testing Service / ", path: "" },
    {
      title: " Results / My Tests",
      path: siteRoutes.eportalTestingServiceListing,
    },
  ];
  const [applyTest, setApplyTest] = useState<boolean>(false);
  const goToApplyTest = () => {
    setApplyTest(true);
  };

  return (
    <Fragment>
      <TestingServiceListingMain>
        <TestingServiceListingTop>
          <div className="left">
            <span className="page-heading">Results / My Tests</span>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <div className="right"></div>
        </TestingServiceListingTop>
        <TestingServiceListingSection
          className="content-radius-shadow"
          isTableOverflowing={false}
        >
          <div>
            <span className="table-heading">Test Results </span>
          </div>
          <div className="list-header">
            <div className="table-data-export-buttons">
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>

              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            </div>
            <div className="table-search-field">
              <span className="search-icon">
                <SearchFieldSvg className="icon" />
              </span>
              <input type="search" placeholder="Search" />
            </div>
          </div>
          <div className="data-table">
            <table className="bottom-bordered-cells">
              <thead>
                <tr>
                  {columns.map((column: string, index: number) => {
                    return <th key={index}>{column}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((application, index) => {
                  return (
                    <Fragment key={index}>
                      <tr
                        className={`expandable ${
                          (application as any).isExpanded && "opened"
                        }`}
                      >
                        <td>258865</td>
                        <td>
                          <div className="mw-150">Admission Test (for FAT)</div>
                        </td>
                        <td>LHR Campus (Main)</td>
                        <td>2021-03-02</td>
                        <td>2021-03-11</td>
                        <td>100</td>
                        <td>82</td>
                        <td>Pass</td>
                        <td>
                          <span className="status-tile green">Paid</span>
                        </td>

                        <td>
                          <div className="table-action-icons">
                            <div className="action-menu">
                              <div
                                className="menu-icon"
                                onClick={() => toggleRowExpand(index)}
                              >
                                <TabPrimaryActionMenu className="icon" />
                              </div>
                              {(application as any).isDropdownOpen && (
                                <div className="table-menu-dropdown">
                                  <div className="particular-menu cp">
                                    <div className="action-icon">
                                      <DownloadApplicationSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Download Result Card
                                    </span>
                                  </div>
                                  <div className="particular-menu cp">
                                    <div className="action-icon">
                                      <DownloadDocumentSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Download Roll No Slip
                                    </span>
                                  </div>
                                  <div className="particular-menu cp">
                                    <div className="action-icon">
                                      <DownloadChallanSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Download Challan
                                    </span>
                                  </div>
                                  <div className="particular-menu cp">
                                    <div className="action-icon">
                                      <UploadChallanSvg className="icon" />
                                    </div>
                                    <span className="title">
                                      Upload Challan
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Fragment>
            <DataNotFound show={!data.length && !isLoading} />
          </Fragment>
        </TestingServiceListingSection>
        <TestListingSection className="content-radius-shadow">
          <div className="heading">
            <span> Available Test Schedule</span>
          </div>
          <TestWrapper>
            {[1, 2, 3].map((item: number, index: number) => {
              return (
                <TestCard key={index}>
                  <div className="card-header">
                    <div className="header-text">
                      <span className="title">Test: IUB-HAT (for PhD)</span>
                      <span className="batch">
                        IUB Admission Test (for PhD) - Batch 7
                      </span>
                    </div>
                  </div>
                  <div className="info-section">
                    <div className="info">
                      <span className="title">Start Date:</span>
                      <span className="detail">2024-09-16</span>
                    </div>
                    <div className="info">
                      <span className="title">Last Date:</span>
                      <span className="detail">11-08-2024 </span>
                    </div>
                    <div className="info">
                      <span className="title">Test Date ( Tentative):</span>
                      <span className="detail"></span>
                    </div>
                    <div className="info">
                      <span className="title">Fee:</span>
                      <span className="detail">Rs. 6000</span>
                    </div>
                  </div>

                  <div className="apply-button">
                    <button className="lg-rounded-btn" onClick={goToApplyTest}>
                      <span className="icon">
                        <SmallWhiteRightArrow />
                      </span>
                      <span className="title">Apply Now</span>
                    </button>
                  </div>
                </TestCard>
              );
            })}
          </TestWrapper>
        </TestListingSection>
      </TestingServiceListingMain>

      {applyTest && <ApplyForTest setOpen={setApplyTest} />}
    </Fragment>
  );
};

export default EportalTestingServices;
